import React, { useState, useEffect } from 'react';
import { getIndicators } from './lib/api';
import { Globe, TrendingUp, BarChart2, Scale, ClipboardList } from 'lucide-react';
import { Link } from 'react-router-dom';
import MapSection from './components/MapSection';

const Home = () => {
  const [indicators, setIndicators] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [indicatorsData] = await Promise.all([getIndicators()]);
        setIndicators(indicatorsData);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {/* Hero Section */}
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8 bg-gradient-to-r from-white to-blue-50">
  <div className="text-center">
    <h1 className="text-5xl font-extrabold text-transparent bg-gradient-to-r from-teal-600 to-indigo-600 bg-clip-text sm:text-6xl md:text-7xl">
      Arbitration Atlas
    </h1>
    <h2 className="mt-5 text-lg text-gray-700 sm:text-xl md:text-2xl">
      Informed Decision-making on Arbitration Seats around the World
    </h2>
    <p className="mt-5 max-w-3xl mx-auto text-base text-gray-600 sm:text-lg md:text-xl">
      Arbitration Atlas provides data on arbitration seats that are less frequently selected. The platform aims to promote geographical diversity and empower contracting parties to make informed decisions.
    </p>

    {/* اضافه کردن لوگو زیر متن */}
    <img
      src="/Arbitration-Atlas.webp"
      alt="Arbitration Atlas Logo"
      className="mt-8 mx-auto w-48 h-48 sm:w-64 sm:h-64 md:w-96 md:h-96"
    />
  </div>
</div>

      <MapSection />

      {/* Introduction Section */}
      <div className="px-4 py-16 bg-gradient-to-r from-indigo-50 to-purple-100">
        <h2 className="text-3xl font-bold text-center text-indigo-700">Welcome to the Atlas of Arbitration</h2>
        <p className="mt-6 text-lg text-center text-gray-600 max-w-3xl mx-auto">
          In the realm of international arbitration, a pressing issue persists: the overwhelming concentration of arbitration in a few dominant regions, particularly Europe. This geographical imbalance...
        </p>
        <div className="max-w-3xl mx-auto mt-8 text-gray-700 text-center">
          <p>Our solution includes customizable standardized arbitration clauses that seamlessly integrate optimal seat selection tailored to the parties' specific needs.</p>
          <Link to="/explore" className="inline-block mt-6 px-6 py-3 text-base font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700">
            Explore the Atlas of Arbitration
          </Link>
        </div>
      </div>

      {/* Main Options */}
      <div className="grid gap-8 px-4 py-12 bg-white sm:px-6 md:grid-cols-2 lg:px-8 lg:gap-12">
        <OptionCard 
          icon={<TrendingUp className="w-6 h-6 text-blue-600" />}
          title="Explore by Indicator"
          description="Explore various indicators to assist in selecting the ideal seat for international arbitration."
          link="/indicator"
          buttonText="View Indicators"
        />
        <OptionCard 
          icon={<Globe className="w-6 h-6 text-blue-600" />}
          title="Explore by Country"
          description="Select a country and gain insights into its seat for international arbitration."
          link="/country"
          buttonText="View Countries"
        />
        {/* استفاده از <a> برای لینک خارجی Google Forms */}
        <div className="transition-shadow duration-300 bg-white rounded-lg shadow-md hover:shadow-lg p-8">
          <div className="flex items-center justify-center w-12 h-12 bg-blue-100 rounded-lg">
            <ClipboardList className="w-6 h-6 text-blue-600" />
          </div>
          <h2 className="mt-6 text-2xl font-semibold text-gray-900">Survey Questionnaire</h2>
          <p className="mt-4 text-gray-500">
            Participate in a survey to assess the effectiveness of arbitration seats across various jurisdictions.
          </p>
          <a 
            href="https://docs.google.com/forms/d/e/1FAIpQLSdRorBMvaHC7AqOJ8VX15YFA5C8ii925LjJl6GPo_4WTCIqxA/viewform" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="inline-flex items-center px-6 py-3 mt-6 font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
          >
            Take the Survey
          </a>
        </div>
      </div>

      {/* Iran Section */}
      <div className="px-4 py-16 bg-gradient-to-r from-blue-500 to-indigo-600 text-white">
        <div className="grid gap-8 md:grid-cols-3">
          <div className="md:col-span-2">
            <div className="flex items-center space-x-2">
              <Scale className="w-6 h-6 text-white" />
              <span className="text-lg font-medium">Islamic Republic of Iran Arbitration Statistics</span>
            </div>
            <h2 className="mt-4 text-3xl font-bold">View comprehensive analysis of Iran as a seat for international arbitrations.</h2>
            <p className="mt-4 text-lg">Dive deep into comprehensive analysis of Iran's budget allocations, spending trends, and financial distributions.</p>
            <Link to="/iran-breakdown" className="inline-flex items-center px-6 py-3 mt-6 font-medium text-blue-600 bg-white rounded-lg hover:bg-blue-50">
              Explore Iran
              <BarChart2 className="w-5 h-5 ml-2" />
            </Link>
          </div>
          <div className="space-y-4">
            <FeatureCard 
              title="Arbitration Institution" 
              icon={<TrendingUp className="w-5 h-5 text-blue-200" />} 
              content={
                <>
                  Arbitration Centre of Iran Chamber (ACIC)<br/>
                  Tel: +9821-88846048<br/>
                  Email: info@arbitration.ir<br/>
                  Website: <a href="https://www.en.arbitration.ir" className="text-blue-100 underline" target="_blank" rel="noopener noreferrer">www.en.arbitration.ir</a>
                </>
              }
            />
            <FeatureCard 
              title="Legislation for International Arbitration" 
              icon={<BarChart2 className="w-5 h-5 text-blue-200" />} 
              content={
                <>
                  <a href="/pdfs/570_the-law-concerning-international-commercial-arbitration-iran.pdf" className="text-blue-100 underline" target="_blank" rel="noopener noreferrer">The Law Concerning International Commercial Arbitration Iran (PDF)</a><br/>
                  <a href="/pdfs/Iran-Law-of-Adhesion-to-New-York-Convention.pdf" className="text-blue-100 underline" target="_blank" rel="noopener noreferrer">Law of Adhesion to New York Convention (PDF)</a>
                </>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

const OptionCard = ({ icon, title, description, link, buttonText }) => (
  <div className="transition-shadow duration-300 bg-white rounded-lg shadow-md hover:shadow-lg p-8">
    <div className="flex items-center justify-center w-12 h-12 bg-blue-100 rounded-lg">{icon}</div>
    <h2 className="mt-6 text-2xl font-semibold text-gray-900">{title}</h2>
    <p className="mt-4 text-gray-500">{description}</p>
    <Link to={link} className="inline-flex items-center px-6 py-3 mt-6 font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700">
      {buttonText}
    </Link>
  </div>
);

const FeatureCard = ({ title, icon, content }) => (
  <div className="p-4 bg-white/10 rounded-xl backdrop-blur-sm">
    <div className="flex items-center mb-2">{icon}<h3 className="ml-2 text-lg font-semibold text-white">{title}</h3></div>
    <p className="text-blue-100">{content}</p>
  </div>
);

export default Home;
