import React, { useState } from 'react';

const Explore = () => {
  const [selectedIndicators, setSelectedIndicators] = useState([]);

  // اطلاعات صندلی داوری هر کشور
  const arbitrationData = {
    Armenia: {
      seatName: 'Yerevan',
      nyConvention: '1997',
      nationalLaw: '2006',
      institution: 'AMCA',
      link: 'https://amca.am/',
    },
    Bahrain: {
      seatName: 'Manama',
      nyConvention: '1988',
      nationalLaw: '2015',
      institution: 'BCDR',
      link: 'https://bcdr.org/',
    },
    Oman: {
      seatName: 'Muscat',
      nyConvention: '1999',
      nationalLaw: '1997',
      institution: 'Oman Commercial Arbitration Center',
      link: 'https://omanarbitration.om/',
    },
    Qatar: {
      seatName: 'Doha',
      nyConvention: '2002',
      nationalLaw: '2017',
      institution: 'QICCA - Qatar International Center for Conciliation and Arbitration',
      link: '',
    },
    UAE: {
      seatName: 'Dubai, Abu Dhabi',
      nyConvention: '2006',
      nationalLaw: '2018',
      institutions: [
        { name: 'DIAC', link: 'https://www.diac.com/en/home/' },
        { name: 'Abu Dhabi International Arbitration Centre (arbitrateAD)', link: 'https://arbitratead.ae/' },
      ],
    },
    Turkey: {
      seatName: 'Istanbul',
      nyConvention: '1991',
      nationalLaw: '2001',
      institution: 'ISTAC',
      link: 'https://istac.org.tr/en/',
    },
    Russia: {
      seatName: 'Moscow',
      nyConvention: '1960',
      nationalLaw: '1993',
      institution: 'Russian Arbitration Centre',
      link: 'https://centerarbitr.ru/en/main-page/',
    },
    Georgia: {
      seatName: 'Tbilisi',
      nyConvention: '2009',
      nationalLaw: '2012',
      institution: 'GIAC',
      link: 'https://giac.ge/en',
    },
    Egypt: {
      seatName: 'Cairo',
      nyConvention: '1994',
      nationalLaw: '1994',
      institution: 'CRCICA',
      link: 'https://crcica.org/',
    },
    Iran: {
      seatName: 'Tehran',
      nyConvention: '2001',
      nationalLaw: '1997',
      institution: 'ACIC',
      link: 'https://en.arbitration.ir/',
    },
  };

  // لیست کامل اندیکاتورها
  const indicators = [
    { id: 'robust-legal-framework', name: 'Robust Legal Framework' },
    { id: 'judicial-independence', name: 'Judicial Independence and Competence' },
    { id: 'legal-expertise', name: 'Access to Legal Expertise' },
    { id: 'education-training', name: 'Commitment to Education and Training' },
    { id: 'right-representation', name: 'Right to Representation' },
    { id: 'accessibility-safety', name: 'Accessibility and Safety Measures' },
    { id: 'infrastructure-facilities', name: 'Infrastructure and Facilities' },
    { id: 'ethical-standards', name: 'Ethical Standards' },
    { id: 'enforceability', name: 'Enforceability' },
  ];

  const handleIndicatorSelect = (indicatorId) => {
    setSelectedIndicators((prev) => {
      if (prev.includes(indicatorId)) {
        return prev.filter((id) => id !== indicatorId);
      } else {
        return [...prev, indicatorId];
      }
    });
  };

  // فیلتر کردن کشورها بر اساس اندیکاتورهای انتخاب‌شده
  const filteredArbitrationData = Object.entries(arbitrationData).filter(([countryName, data]) => {
    if (selectedIndicators.length === 0) return true;
    return selectedIndicators.every((indicator) => {
      if (indicator === 'robust-legal-framework') return data.nyConvention && data.nationalLaw;
      if (indicator === 'infrastructure-facilities') return data.institution;
      return true; // سایر اندیکاتورها در این مثال قابلیت‌های دلخواه دارند
    });
  });

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <h1 className="text-3xl font-bold text-blue-500 mb-4">Explore by Country and Indicator</h1>
      <p className="text-lg text-gray-700 mb-6">
        Select indicators to see information about different countries and their arbitration seats.
      </p>

      {/* انتخاب اندیکاتورها */}
      <div className="mb-8 w-full max-w-3xl p-6 bg-white rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4">Select Indicators</h2>
        <div className="space-y-2">
          {indicators.map((indicator) => (
            <label key={indicator.id} className="flex items-center">
              <input
                type="checkbox"
                className="mr-2"
                checked={selectedIndicators.includes(indicator.id)}
                onChange={() => handleIndicatorSelect(indicator.id)}
              />
              <span>{indicator.name}</span>
            </label>
          ))}
        </div>
      </div>

      {/* نمایش اطلاعات کشورها */}
      <div className="grid gap-8 w-full max-w-7xl md:grid-cols-2 lg:grid-cols-3">
        {filteredArbitrationData.map(([countryName, data]) => (
          <div key={countryName} className="p-6 bg-white rounded-lg shadow">
            <h3 className="text-2xl font-bold text-gray-800 mb-2">{countryName}</h3>
            <p className="text-lg text-gray-600">Seat: {data.seatName}</p>

            {/* نمایش اطلاعات مربوط به اندیکاتورهای انتخاب‌شده */}
            {selectedIndicators.includes('robust-legal-framework') && (
              <div className="mt-4">
                <h4 className="text-lg font-semibold text-gray-700">Robust Legal Framework</h4>
                <p>New York Convention membership: {data.nyConvention}</p>
                <p>National Law based on UNCITRAL Model Law: {data.nationalLaw}</p>
              </div>
            )}
            {selectedIndicators.includes('infrastructure-facilities') && (
              <div className="mt-4">
                <h4 className="text-lg font-semibold text-gray-700">Infrastructure and Facilities</h4>
                <p>Arbitration Institution: {data.institution}</p>
                {data.link && (
                  <p>
                    Link: <a href={data.link} className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">More Info</a>
                  </p>
                )}
              </div>
            )}
            {/* سایر اندیکاتورها را می‌توانید در این بخش اضافه کنید */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Explore;
