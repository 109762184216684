import React from "react";
import { MapContainer, TileLayer, GeoJSON, CircleMarker, Tooltip } from "react-leaflet";
import { motion } from "framer-motion";
import "leaflet/dist/leaflet.css";
import geoData from "./custom.geo.json"; // مسیر فایل GeoJSON

const markers = [
  { name: "Tehran", coordinates: [35.689, 51.389] },
  { name: "Dubai", coordinates: [25.2048, 55.2708] },
  { name: "Istanbul", coordinates: [41.0082, 28.9784] },
  { name: "Cairo", coordinates: [30.0444, 31.2357] },
  { name: "Tbilisi", coordinates: [41.7151, 44.8271] },
  { name: "Moscow", coordinates: [55.7558, 37.6173] },
  { name: "Ankara", coordinates: [39.9334, 32.8597] },
  { name: "Doha", coordinates: [25.2854, 51.531] },
  { name: "Muscat", coordinates: [23.5859, 58.5933] },
  { name: "Manama", coordinates: [26.2285, 50.586] },
  { name: "Yerevan", coordinates: [40.1833, 44.5126] },
];

const MapSection = () => {
  const isMobile = window.innerWidth < 768;
  const mapCenter = isMobile ? [34, 45] : [33, 47];
  const mapZoom = isMobile ? 4 : 5;
  const mapHeight = isMobile ? "400px" : "500px";

  return (
    <div className="map-container" style={{ height: mapHeight, width: "100%", backgroundColor: "#000" }}>
      <MapContainer
        center={mapCenter}
        zoom={mapZoom}
        scrollWheelZoom={false}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
        />

        <GeoJSON
          data={geoData}
          style={{
            color: "white",
            weight: 1.5,
            fillColor: "transparent",
            fillOpacity: 0,
          }}
        />

        {markers.map(({ name, coordinates }, index) => (
          <CircleMarker
            key={index}
            center={coordinates}
            radius={10}
            pathOptions={{ color: "transparent", fillColor: "orange", fillOpacity: 0.8 }}
          >
            <Tooltip direction="top" offset={[0, -10]} opacity={1} permanent>
              {name}
            </Tooltip>

            {/* Motion Div for Pulsing Effect */}
            <motion.div
              style={{
                position: "absolute",
                top: -10,
                left: -10,
                width: 20,
                height: 20,
                borderRadius: "50%",
                backgroundColor: "orange",
              }}
              animate={{
                scale: [1, 1.5, 1],
                opacity: [0.6, 1, 0.6],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                repeatType: "loop",
              }}
            />
          </CircleMarker>
        ))}
      </MapContainer>
    </div>
  );
};

export default MapSection;
